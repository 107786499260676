import React, { useEffect, useState } from "react";
import ProductList from "../components/ProductList";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const heroImage = products.length > 0 ? products[0].picture : "";


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/products/");
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  
  return (
    <div className="homepage">
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[400px]"
        style={{
          backgroundImage: `url("${heroImage}")`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-center">
          <div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Welcome to ProPrimeLabs
            </h1>
            <p className="text-lg md:text-xl text-white">
              Your One-Stop Shop for Premium Supplements
            </p>
            <button
            className="mt-6 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
            onClick={() => navigate("/shop")}
          >
            Shop Now
          </button>
          </div>
        </div>
      </section>

      {/* Promotional Section */}
      <section className="py-16 bg-gray-100 text-center">
        <h2 className="text-3xl font-bold mb-6">Why Choose Us?</h2>
        <div className="flex flex-wrap justify-center space-x-6">
          <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
            <h3 className="text-xl font-semibold">Premium Quality</h3>
            <p className="text-gray-600">Top-notch supplements for every need.</p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
            <h3 className="text-xl font-semibold">Affordable Prices</h3>
            <p className="text-gray-600">Get the best deals on every product.</p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
            <h3 className="text-xl font-semibold">Fast Shipping</h3>
            <p className="text-gray-600">Delivered to your doorstep in no time.</p>
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section className="py-16 bg-white">
        <h2 className="text-3xl font-bold text-center mb-8">Our Products</h2>
        <ProductList products={products} />
      </section>

      {/* Footer */}
    
    </div>
  );
};

export default Homepage;
