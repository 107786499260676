import React, { useContext } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import { CartContext } from './context/CartContext';
import CheckoutPageSuccess from "./pages/CheckoutPageSuccess";
import TrackOrder from './pages/TrackOrder';
import ProductDetails from './components/ProductDetails';
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Shop from "./pages/Shop";
import Homepage from './pages/Homepage';

function App() {
  const { cart } = useContext(CartContext);

  return (
    <div className="container mx-auto">
      {/* Responsive Header */}
      <header className="bg-blue-600 text-white p-4">
        <nav className="flex justify-between items-center">
          <h1 className="text-2xl"><Link to="/">ProPrimeLabs</Link></h1>
          <button
            className="block md:hidden bg-white text-blue-600 px-2 py-1 rounded"
            onClick={() => {
              const menu = document.getElementById('mobile-menu');
              if (menu) menu.classList.toggle('hidden');
            }}
          >
            Menu
          </button>
          <ul id="mobile-menu" className="hidden md:flex space-x-4">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/account">Account</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
            <li><Link to="/shop">Shop</Link></li>
            <li><Link to="/cart">Cart ({cart.length})</Link></li>
            <li><Link to="/checkout">Checkout</Link></li>
            <li><Link to="/track_order">Track Order</Link></li>
          </ul>
        </nav>
      </header>

      {/* Main Content */}
      <main>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/cart" element={<CartPage cart={cart} />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/checkoutpagesuccess" element={<CheckoutPageSuccess />} />
          <Route path="/track_order" element={<TrackOrder />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white p-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; {new Date().getFullYear()} ProPrimeLabs. All Rights Reserved.</p>
          <ul className="flex justify-center space-x-4 mt-4">
            <li><Link to="/" className="text-blue-400 hover:underline">Home</Link></li>
            <li><Link to="/about-us" className="text-blue-400 hover:underline">About Us</Link></li>
            <li><Link to="/contact-us" className="text-blue-400 hover:underline">Contact Us</Link></li>
            <li><Link to="/shop" className="text-blue-400 hover:underline">Shop</Link></li>
            <li><Link to="/cart" className="text-blue-400 hover:underline">Cart</Link></li>
            <li><Link to="/checkout" className="text-blue-400 hover:underline">Checkout</Link></li>
            <li><Link to="/track_order" className="text-blue-400 hover:underline">Track Order</Link></li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default App;
