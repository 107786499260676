import React from "react";

function AboutUs() {
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="text-gray-700 mb-4">
        Welcome to ProPrimeLabs! We are passionate about providing high-quality supplements for strenght,muscle enhancements, and performance for men of all ages.Our products are incredibly powerful for males 30 and above.
      </p>
      <p className="text-gray-700">
        Our mission is to be the number #1 place where men come to get products that help them reach their potential. As we grow older we need an extra Push
        Things do not come to us as easy as before. In a  fast paced world of today , we want you to know that you are not alone we are here to help and support be at the top of your game as you become more aged 
      </p>
      <p>Our motto at ProPrimeLabs is "Pro performance until death" </p>
    </div>
  );
}

export default AboutUs;
