import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function CheckoutPageSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  useEffect(() => {
    //console.log(location.state);
    if (location.state && location.state.order_id) {
      const fetchOrderDetails = async () => {
        try {
          const response = await fetch(`https://supplement-app-d151447d38d4.herokuapp.com/api/orders/${location.state.order_id}`);
          if (response.ok) {
            const data = await response.json();
            setOrderDetails(data);
          } else {
            console.error("Failed to fetch order details.");
          }
        } catch (error) {
          console.error("Error fetching order details:", error);
        }
      };
      fetchOrderDetails();
    }
  }, [location.state]);

  if (!orderDetails) {
    return <p className="text-center mt-10 text-gray-600">Loading order details...</p>;
  }

  const { buyer = {}, items = [], totalAmount, couponCode, shippingMethod = {}, paymentMethod = {} } = orderDetails;
  return (
    <div className="checkout-page-success container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Order Confirmation</h2>
      <p className="mb-6">Thank you for your order! Here are your order details:</p>

      {/* Order Summary */}
      <div className="order-summary bg-gray-100 p-4 rounded-md mb-6">
        <h3 className="text-lg font-semibold mb-2">Order Summary</h3>
        <p>Order ID: <span className="font-medium">{orderDetails._id}</span></p>
        <p>Order Status: <span className="font-medium">{orderDetails.status}</span></p>
        <p>Total Amount: <span className="font-medium">${totalAmount.toFixed(2)}</span></p>
        {couponCode && <p>Coupon Code: <span className="font-medium">{couponCode}</span></p>}
        <h4 className="text-md font-semibold mt-4">Items:</h4>
        <ul className="list-disc pl-5">
          {items.map((item, index) => (
            <li key={index}>
              {item.name} - Quantity: {item.quantity} - Price: ${item.price.toFixed(2)}
            </li>
          ))}
        </ul>
      </div>
      <div className="payment-summary bg-gray-100 p-4 rounded-md mb-6">
        <h3 className="text-lg font-semibold mb-2">Payment procedure</h3>
        <p>Payment name: <span className="font-medium">{paymentMethod.name}</span></p>
        <p>Paymnet Description: <span className="font-medium">{paymentMethod.description}</span></p>
      </div>

      {/* Buyer Details */}
      <div className="buyer-details bg-gray-100 p-4 rounded-md mb-6">
        <h3 className="text-lg font-semibold mb-2">Buyer Details</h3>
        <ul>
          <li>Name: <span className="font-medium">{buyer.firstName || "N/A"}</span></li>
          <li>Email: <span className="font-medium">{buyer.email || "N/A"}</span></li>
          <li>Phone: <span className="font-medium">{buyer.phone || "N/A"}</span></li>
          <li>Address: <span className="font-medium">{buyer.streetAddress || "N/A"}</span></li>
        </ul>
      </div>

      {/* Payment and Shipping Details */}
      <div className="shipping-payment-info bg-gray-100 p-4 rounded-md">
        <h3 className="text-lg font-semibold mb-2">Shipping and Payment Details</h3>
        <h4 className="text-md font-semibold mt-2">Shipping Method</h4>
        <p>
          {shippingMethod.name || "N/A"} - ${shippingMethod.cost?.toFixed(2) || "0.00"}{" "}
          ({shippingMethod.estimatedDeliveryTime || "Unknown"})
        </p>
      </div>

      {/* Navigation Button */}
      <div className="text-center mt-6">
        <button
          onClick={() => navigate("/")}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Continue Shopping
        </button>
      </div>
    </div>
  );
}

export default CheckoutPageSuccess;
